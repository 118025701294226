export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "PURCHASE_TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Compra"])},
          "PENDING_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])}
        },
        "BUTTONS": {
          "APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar Pago"])},
          "INVOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturar"])}
        }
      }
    }
  })
}
