<template>
    <div class="card mb-1 mb-xl-10">
        <div class="card-body pb-1">
            <div class="card-title d-flex justify-content-between">
                <div class="d-flex justify-content-start">
                    <h3 class="fw-bolder ml-2">{{ $t('TITLE') }}</h3>
                </div>
            </div>
            <form class="row g-3">
                <div class="col-md-4">
                    <label for="inputEmail4" class="form-label"><b>Folio:</b></label> {{ getFolio }}
                </div>
                <div class="col-md-4">
                    <label for="inputEmail4" class="form-label"><b>Estatus:</b></label> {{ getStatus }}
                </div>
                <div class="col-md-4">
                    <label for="inputEmail4" class="form-label"><b>Tipo:</b></label> {{ getType }}
                </div>
                <div class="col-md-4">
                    <label for="inputEmail4" class="form-label"><b>Subtotal:</b></label> {{ formatNumeric(getSubtotal) }}
                </div>
                <div class="col-md-4">
                    <label for="inputEmail4" class="form-label"><b>Impuesto:</b></label> {{ formatNumeric(getTax) }}
                </div>
                <div class="col-md-4">
                    <label for="inputEmail4" class="form-label"><b>Total:</b></label> {{ formatNumeric(getTotal) }}
                </div>
                <div class="col-md-12">
                    <label for="inputEmail4" class="form-label"><b>Cliente:</b></label> {{ getCustomer }}
                </div>
                <div class="col-md-8">
                    <label for="inputEmail4" class="form-label"><b>Cajero:</b></label> {{ getAgent }}
                </div>
                <div class="col-md-4">
                    <label for="inputEmail4" class="form-label"><b>Fecha Creación:</b></label> {{ getCreatedDate }}
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue';
import { formatNumeric } from "@/core/shared/functions/Formatter"

export default defineComponent({
    name: "current-purchase",
    props: {
        purchase: {
            type: Object
        }
    },
    setup(props) {
        let { purchase } = toRefs(props);

        //COMPUTEDS
        const getFolio = computed(() => purchase.value ? purchase.value.invoince : 0);
        const getStatus = computed(() => purchase.value ? purchase.value.isPayed ? 'Pagado' : "Pendiente" : '');
        const getType = computed(() => purchase.value ? purchase.value.saleTypeName : '');
        const getCustomer = computed(() => purchase.value ? purchase.value.customer : '');
        const getAgent = computed(() => purchase.value ? purchase.value.agent : '');
        const getSubtotal = computed(() => purchase.value ? purchase.value.subTotal : 0);
        const getTax = computed(() => purchase.value ? purchase.value.tax : 0);
        const getTotal = computed(() => purchase.value ? purchase.value.total : 0);
        const getCreatedDate = computed(() => purchase.value ? purchase.value.fechaCreacion : '');

        return {
            //Computeds
            getFolio
            , getStatus
            , getType
            , getCustomer
            , getAgent
            , getSubtotal
            , getTax
            , getTotal
            , getCreatedDate

            //Functions
            , formatNumeric
        }
    },
})
</script>


<i18n>
{
    "es": {
        "TITLE": "Datos de la Compra"
    }
}
</i18n>