export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "ACC_PAYMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Compra: "])}
        },
        "BUTTONS": {
          "PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Pagos"])}
        }
      }
    }
  })
}
