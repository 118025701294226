<template>
<ModalForm
    id="modalPurchasePayments"
    :title="$t('TITLE')"
    ref="modalForm"
    :mw="'mw-700px'"
    :textCloseBtn="'Cerrar'"
    :readOnly="true"
    :requireHeaderLine="false"
    :requireFooter="false"
>
    <Datatable :headers="headers" :requireActions="false" :requireStatusCol="false">
        <Datarow 
            v-for="row in renderRows" 
            v-bind:key="row.ID" 
            :dataRow="row" 
            :showCrudActions="false"
            :requireStatusCol="false"
            :statusPropName="'isActive'"
        >
        </Datarow>
    </Datatable>
</ModalForm>
</template>

<script>
import { defineComponent, ref, reactive, computed, toRefs, onBeforeMount } from 'vue';
import ModalForm from "../../components/c-modal-form/ModalFormMaster.vue";
import Datatable from "../../components/c-datatable/Datatable.vue";
import Datarow from "../../components/c-datatable/Datarow.vue";
import { formatToDatatable } from "../../common/formaterToDatatable";
import ApiService from "@/core/services/ApiService";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';

export default defineComponent({
    name: "purchase-payments",
    components: {
        ModalForm
        , Datatable
        , Datarow
    },
    props: {
        purchaseId: {
            type: String,
            required: false,
            default: () => ""
        }
    },
    setup(props) {
        //VARIABLES Y CONSTANTES
        let { purchaseId } = toRefs(props);
        const messageService = new SwalMessageService();
        let records = ref([]);
        let modalForm = ref(null);
        let paymentTypes = ref([]);
        let currency = ref([]);
        const configTable = reactive([
            { PropName: "paymentId", HeadLabel: "id", Type: "text", VisibleInGrid: false },
            { PropName: "paymentType", HeadLabel: "Tipo de Pago", Type: "text", VisibleInGrid: true },
            { PropName: "amount", HeadLabel: "Importe", Type: "moneda", VisibleInGrid: true },
            { PropName: "currency", HeadLabel: "Moneda", Type: "text", VisibleInGrid: true },
            { PropName: "chargeReference", HeadLabel: "Referencia", Type: "text", VisibleInGrid: true },
            { PropName: "fecha", HeadLabel: "Fecha", Type: "text", VisibleInGrid: true }
        ]);

        //HOOKS
        onBeforeMount(async() => {
            await getComboOptions("WayToPay", paymentTypes);
            await getComboOptions("Currency", currency);
        });

        //COMPUTEDS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable, "isActive");
        });

        //FUNCTIONS
        const initDefaults = () => {
            ApiService.get(`Charges/${purchaseId.value}`).then(res => {
                if(res.status == 200) {
                    records.value = res.data.chargeDetails.map(cd => {
                        let objPay = {
                            paymentId: cd.chargeId,
                            paymentType: paymentTypes.value.some(p => p.id == cd.paymentTypeId) ? paymentTypes.value.find(p => p.id == cd.paymentTypeId).name : "",
                            amount: cd.amount,
                            currency: currency.value.some(c => c.id == cd.currencyId) ? currency.value.find(c => c.id == cd.currencyId).name : "",
                            chargeReference: cd.chargeReference,
                            fecha: cd.fecha
                        }

                        return objPay;
                    });
                }
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboOptions = async(resource, container, parentId = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo`;
            ApiService.get(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        return {
            modalForm

            //Computeds
            , headers
            , renderRows

            //Functions
            , initDefaults
        }
    },
})
</script>

<i18n>
{
    "es": {
        "TITLE": "Pagos",
        "BUTTONS": {
            "NEW_PAYMENT": "Añadir pago..."
        }
    }
}
</i18n>
