<template>
    <section id="bill-sales-products-table">
        <ModuleMaster
            :requirePaginator="false"
            :numItemsPaginator="totalRecords"
            :itemsPerPage="sizePage"
            :requireSearchField="false"
            :requireAddButton="false"
            :useExtraButtons="true">
            <template #extra-buttons>
                <button
                    :disabled="!existsPurchase"
                    type="button"
                    class="btn btn-sm  btn-primary btn-active-light-primary"
                    @click="onClickPayments"
                >
                    <i class="bi bi-receipt-cutoff text-center fs-2"></i>
                    {{$t("BUTTONS.PAY") }}
                </button>
            </template>
            <Datatable :headers="headers" 
                :requireActions="false" 
                :requireStatusCol="false"
                :requireSelectMultiple="false"
            >
                <Datarow 
                    v-for="row in renderRows" 
                    v-bind:key="row.ID" 
                    :dataRow="row" 
                    :showCrudActions="true"
                >
                </Datarow>
            </Datatable>
        </ModuleMaster>
    </section>
</template>

<script>

import { defineComponent, ref, reactive, computed, onMounted, toRefs } from 'vue';
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import {formatNumeric} from "@/core/shared/functions/Formatter";

export default defineComponent({
    name: "purchase-detail-list",
    components: {
        ModuleMaster,
        Datatable,
        Datarow
    },
    props: {
        purchaseToPay: {
            type: Object,
            default: () => null
        }
    },
    setup(props, context) {
        //VARIABLES Y CONSTANTES
        let records = ref([]);
        let { purchaseToPay } = toRefs(props);

        //HOOKS
        // onMounted(() => {

        // });

        //VARIABLES Y CONSTANTES
        const configTable = reactive([
            { PropName: "productName", HeadLabel: "Producto", Type: "text", VisibleInGrid: true },
            { PropName: "unitOfMeasurementName", HeadLabel: "Unidad de Medida", Type: "text", VisibleInGrid: true },
            { PropName: "amount", HeadLabel: "Cantidad", Type: "text", VisibleInGrid: true },        
            { PropName: "price", HeadLabel: "Precio", Type: "moneda", VisibleInGrid: true },
            { PropName: "discount", HeadLabel: "Descuento", Type: "moneda", VisibleInGrid: true },
            { PropName: "subTotal", HeadLabel: "Importe", Type: "moneda", VisibleInGrid: true },             
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },        
        ]);

        //COMPUTEDS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const totalPurchase = computed(() => {
            return purchaseToPay.value ? purchaseToPay.value.total : 0;
        });

        const renderRows = computed(() => {
            let arrTmp = purchaseToPay.value && purchaseToPay.value.saleDetails ? [...purchaseToPay.value.saleDetails] : null;
            return formatToDatatable(arrTmp, configTable);
        });

        const existsPurchase = computed(() => {
            return purchaseToPay.value != null;
        });

        //FUNCTIONS
        const onClickPayments = () => {
            context.emit("onClickPaymentsButton");
        }

        return{
            headers

            //Computeds
            , existsPurchase
            , totalPurchase
            , renderRows

            //Functions
            , formatNumeric
            , onClickPayments
        }
    },
})
</script>

<i18n>
{
    "es": {
        "LABELS": {
            "ACC_PAYMENTS": "Total Compra: "
        },
        "BUTTONS": {
            "PAY": "Ver Pagos"
        }
    }
}
</i18n>
